<template>
  <div>
    <v-form>
      <p class="mb-3">
        A schematic for the the Williamson ether synthesis between 2-naphthol and 1-bromobutane in
        strong base is shown below.
      </p>

      <p class="pl-4 mb-6">
        <v-img style="max-width: 591px" :src="imageName" />
      </p>

      <p class="mb-2">
        This reaction was carried out using
        <stemble-latex content="$0.724\,\text{g}$" />
        of 2-naphthol and a slight excess of 1-bromobutane. If this resulted in
        <stemble-latex content="$0.486\,\text{g}$" />
        of 2-butoxynaphthalene, determine the theoretical yield (in g) and percent yield for this
        reaction.
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-3"
        prepend-text="$\text{a) Theoretical yield}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.input2"
        prepend-text="$\text{b) Percent yield}:$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';

export default {
  name: 'ChemUCI51LBA4Q5',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/uci51lb-a4-q5.png';
    },
  },
};
</script>
